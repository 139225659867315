(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/common/create-competition-model.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/common/create-competition-model.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let normalizeCompetition;
let resolveTimeZone;
let constants;
let DrawState;
let logger;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  normalizeCompetition = require('../../competition-helper/assets/javascripts/normalize-competition.es6').normalizeCompetition;
  resolveTimeZone = trinidad.components.require('lb-utils', 'helper-formatdate').api.resolveTimeZone;
  constants = require('./constants.es6');
  DrawState = require('../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6').DrawState;
  logger = require('../server/logger');
} else {
  normalizeCompetition = svs.components.marketplaceCompetition.competitionHelper.normalizeCompetition.normalizeCompetition;
  resolveTimeZone = svs.components.lbUtils.helpers.formatDate.resolveTimeZone;
  constants = svs.components.marketplaceCompetition.competitionHandler.common.constants;
  DrawState = svs.components.sport.tipsenShared.DrawState;
  logger = svs.components.marketplaceCompetition.competitionHandler.client.logger;
}
const {
  competitionStates,
  competitionBackendState
} = constants;
const {
  UPCOMING,
  FINISHED,
  REG_OPEN_DRAW_DEFINED,
  REG_CLOSED_DRAW_DEFINED,
  REG_OPEN_DRAW_OPEN,
  REG_CLOSED_DRAW_OPEN,
  REG_OPEN_DRAW_LIVE,
  REG_CLOSED_DRAW_LIVE,
  REG_OPEN_DRAW_FINALIZED,
  REG_CLOSED_DRAW_FINALIZED,
  NO_COMPETITION
} = competitionStates;
const competitionSymbol = Symbol('CompetitionModel');
const isCompetitionModel = instance => (instance === null || instance === void 0 ? void 0 : instance._symbol) === competitionSymbol;
const isRegistrationAllowed = (model, draw) => {
  var _model$getData$detail;
  const numGraceDraws = ((_model$getData$detail = model.getData().details) === null || _model$getData$detail === void 0 ? void 0 : _model$getData$detail.numGraceDraws) || 0;
  const drawNumber = draw.getDrawNumber();
  const drawNumberByGrace = model.getDrawNumber(numGraceDraws);
  const drawState = draw.getDrawState();
  return drawNumber < drawNumberByGrace || drawNumber === drawNumberByGrace && drawState < DrawState.Regclosed;
};

const resolveDrawStatus = (draw, model) => {
  var _competitionData$deta, _competitionData$deta2;
  const competitionData = model.getData();
  const currentTime = resolveTimeZone(new Date());
  const isBeforeStartTime = currentTime < resolveTimeZone(new Date((_competitionData$deta = competitionData.details) === null || _competitionData$deta === void 0 ? void 0 : _competitionData$deta.startTime));
  const isBeforeRegistrationStartTime = currentTime < resolveTimeZone(new Date(competitionData === null || competitionData === void 0 ? void 0 : competitionData.signupStartTime));
  const canRegister = !isBeforeRegistrationStartTime && isRegistrationAllowed(model, draw);
  const isComplete = ((_competitionData$deta2 = competitionData.details) === null || _competitionData$deta2 === void 0 ? void 0 : _competitionData$deta2.state) === competitionBackendState.COMPETITION_COMPLETE;
  if (model.isLastOrPastLastDrawNumber() && draw.getDrawState() >= DrawState.Finalized || isComplete) {
    return FINISHED;
  } else if (isBeforeStartTime && isBeforeRegistrationStartTime) {
    return UPCOMING;
  } else if (!canRegister) {
    if (draw.getDrawState() >= DrawState.Finalized) {
      return REG_CLOSED_DRAW_FINALIZED;
    } else if (draw.getDrawState() >= DrawState.Regclosed) {
      return REG_CLOSED_DRAW_LIVE;
    } else if (draw.getDrawState() >= DrawState.Opened) {
      return REG_CLOSED_DRAW_OPEN;
    } else if (draw.getDrawState() >= DrawState.Defined) {
      return REG_CLOSED_DRAW_DEFINED;
    }
  } else if (canRegister) {
    if (draw.getDrawState() >= DrawState.Finalized) {
      return REG_OPEN_DRAW_FINALIZED;
    } else if (draw.getDrawState() >= DrawState.Regclosed) {
      return REG_OPEN_DRAW_LIVE;
    } else if (draw.getDrawState() >= DrawState.Opened) {
      return REG_OPEN_DRAW_OPEN;
    } else if (draw.getDrawState() >= DrawState.Defined) {
      return REG_OPEN_DRAW_DEFINED;
    }
    return REG_OPEN_DRAW_OPEN;
  }
  return NO_COMPETITION; 
};

const createCompetitionModel = (data, draw) => {
  const instance = {
    get _symbol() {
      return competitionSymbol;
    }
  };
  let model = data === null || data === void 0 ? void 0 : data.model;
  let timestamp = resolveTimeZone((data === null || data === void 0 ? void 0 : data.timestamp) || Date.now());
  let _currentDrawNumber;

  instance.setData = data => {
    timestamp = resolveTimeZone(Date.now());
    model = normalizeCompetition(data);
  };

  instance.getData = () => {
    if (!model) {
      return undefined;
    }
    return _objectSpread(_objectSpread({}, model), {}, {
      details: _objectSpread(_objectSpread({}, model.details), {}, {
        currentDrawNumber: instance.getCurrentDrawNumber()
      })
    });
  };

  instance.getSnapshot = () => {
    const newModel = instance.getData();
    return JSON.parse(JSON.stringify(newModel));
  };

  instance.getSystemSize = () => model.details.systemNumber;

  instance.getSystemType = () => model.details.systemType;

  instance.getProductId = () => model.details.productId;

  instance.getCompType = () => model.compType;
  instance.getCurrentDrawNumber = () => {
    if (_currentDrawNumber && model.details.currentDrawNumber < _currentDrawNumber) {
      return _currentDrawNumber;
    }
    return model.details.currentDrawNumber;
  };
  instance.getCompetitionType = () => {
    var _model;
    return (_model = model) === null || _model === void 0 ? void 0 : _model.compType;
  };
  instance.getCompetitionDesc = () => {
    var _model2;
    return (_model2 = model) === null || _model2 === void 0 ? void 0 : _model2.compDesc;
  };

  instance.getCompetitionId = () => model.id;

  instance.getLastDrawNumber = () => {
    if (model) {
      return model.details.drawNumberList[model.details.drawNumberList.length - 1];
    }
    return 0;
  };
  instance.getFirstDrawNumber = () => {
    if (model) {
      return model.details.drawNumberList[0];
    }
    return 0;
  };

  instance.getDrawNumber = index => {
    if (model) {
      return model.details.drawNumberList[index] || 0;
    }
    return 0;
  };

  instance.getTimestamp = () => timestamp;

  instance.updateCurrentDrawNumber = () => {
    var _drawNumberList;
    const currentDrawNumber = instance.getCurrentDrawNumber();
    const drawNumberList = model.details.drawNumberList;
    const index = drawNumberList.findIndex(did => did === currentDrawNumber);
    const nextDrawNumber = (_drawNumberList = drawNumberList[index + 1]) !== null && _drawNumberList !== void 0 ? _drawNumberList : currentDrawNumber;
    _currentDrawNumber = nextDrawNumber;
    logger.info("Updating currentDrawNumber on id: ".concat(instance.getCompetitionId(), ", current: ").concat(currentDrawNumber, ", next: ").concat(nextDrawNumber));
  };

  instance.isLastDrawNumber = () => {
    const currentDrawNumber = instance.getCurrentDrawNumber();
    const lastDrawNumber = model.details.drawNumberList.slice(-1).pop();
    return currentDrawNumber === lastDrawNumber;
  };
  instance.isLastOrPastLastDrawNumber = () => {
    const currentDrawNumber = instance.getCurrentDrawNumber();
    const lastDrawNumber = model.details.drawNumberList.slice(-1).pop();
    return Boolean(currentDrawNumber >= lastDrawNumber);
  };

  instance.updateCompetitionStatus = draw => {
    var _model$details, _model3;
    if (model && draw) {
      const lastStatus = model.status;
      model.status = resolveDrawStatus(draw, instance);
      if (lastStatus !== model.status) {
        logger.info("Updated competition status, prev status: ".concat(lastStatus, ", new status: ").concat(model.status));
      }
      return;
    }
    if (model && ((_model$details = model.details) === null || _model$details === void 0 ? void 0 : _model$details.state) === competitionBackendState.COMPETITION_COMPLETE) {
      logger.info("Updated competition status to FINISHED when backend state is finished");
      model.status = FINISHED;
      return;
    }
    const isPastRegistrationStartTime = resolveTimeZone(new Date()) > resolveTimeZone(new Date((_model3 = model) === null || _model3 === void 0 ? void 0 : _model3.signupStartTime));
    if (isPastRegistrationStartTime) {
      model.status = REG_OPEN_DRAW_DEFINED;
      return;
    }
    model.status = model ? UPCOMING : undefined;
  };

  instance.getStatus = () => {
    var _model4;
    return (_model4 = model) === null || _model4 === void 0 ? void 0 : _model4.status;
  };
  instance.getBackendState = () => {
    var _model5;
    return (_model5 = model) === null || _model5 === void 0 || (_model5 = _model5.details) === null || _model5 === void 0 ? void 0 : _model5.state;
  };
  instance.isPlayable = isParticipating => {
    var _model8;
    if (isParticipating) {
      var _model6, _model7;
      return ((_model6 = model) === null || _model6 === void 0 ? void 0 : _model6.status) === REG_CLOSED_DRAW_OPEN || ((_model7 = model) === null || _model7 === void 0 ? void 0 : _model7.status) === REG_OPEN_DRAW_OPEN;
    }
    return ((_model8 = model) === null || _model8 === void 0 ? void 0 : _model8.status) === REG_OPEN_DRAW_OPEN;
  };

  instance.isFinished = () => {
    var _model9;
    return ((_model9 = model) === null || _model9 === void 0 ? void 0 : _model9.status) === FINISHED;
  };
  instance.toJSON = () => ({
    model: instance.getData(),
    timestamp: instance.getTimestamp()
  });
  if (model && draw) {
    instance.updateCompetitionStatus(draw);
  }
  return instance;
};
const toExport = {
  createCompetitionModel,
  isCompetitionModel,
  resolveDrawStatus
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.common', toExport);
}

 })(window);